<template>
  <div
    class="inline-flex items-center mb-3 cursor-pointer"
    @click="$emit('back')"
  >
    <icon-back />
    <span class="ml-2 text-xs text-invest-purple">Back</span>
  </div>

  <div class="flex justify-center mt-3">
    <div
      class="
        flex
        items-center
        justify-center
        w-20
        h-20
        rounded-full
        bg-invest-lightgray
      "
    >
      <svg
        width="25"
        height="26"
        viewBox="0 0 25 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.75 11.2857H3.3V19.2857H6.75V11.2857Z" stroke="#656B83" />
        <path
          d="M14.225 11.2857H10.775V19.2857H14.225V11.2857Z"
          stroke="#656B83"
        />
        <path d="M24 21.5714H1V25H24V21.5714Z" stroke="#656B83" />
        <path d="M21.7 11.2857H18.25V19.2857H21.7V11.2857Z" stroke="#656B83" />
        <path d="M12.5 1L1 6.71429V9H24V6.71429L12.5 1Z" stroke="#656B83" />
      </svg>
    </div>
  </div>
  <div class="px-10 mt-8 text-center">
    <h3 class="text-2xl font-bold tracking-base">Withdraw Funds?</h3>
    <p class="mt-2 mb-5 text-xs leading-loose text-invest-dark">
      You are about to withdraw {{ $filters.formatAmount(amount) }} to your Bank
      account.
    </p>
  </div>

  <div class="grid h-16 grid-cols-1 place-content-end">
    <button
      type="button"
      class="btn btn-primary"
      :disabled="loading"
      @click="confirmWithdraw"
    >
      <loader v-if="loading" />
      <span v-else>Yes, Confirm Withdrawal</span>
    </button>
  </div>
</template>
<script lang="ts">
import { defineComponent, SetupContext } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    amount: {
      type: [Number, String],
      required: true,
      validator: (amount: string | number) => amount > 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['back', 'confirmWithdraw'],
  setup: (_props, { emit }: SetupContext) => {
    const confirmWithdraw = () => emit('confirmWithdraw');

    return { confirmWithdraw };
  },
});
</script>
