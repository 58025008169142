
import { defineComponent, SetupContext } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    amount: {
      type: [Number, String],
      required: true,
      validator: (amount: string | number) => amount > 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['back', 'confirmWithdraw'],
  setup: (_props, { emit }: SetupContext) => {
    const confirmWithdraw = () => emit('confirmWithdraw');

    return { confirmWithdraw };
  },
});
